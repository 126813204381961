<template>
    <apexchart width="100%" height="300" type="donut" :options="chartOptions" :series="compSeries"></apexchart>
</template>
<script>
import { mapGetters } from "vuex";
import VueApexCharts from 'vue-apexcharts'
const moment = require("moment");
export default {
    name: "revenuepie",
    props: {
        paymentLinks: Object
    },
    data() {
        return {
            series: [44, 55, 41, 17, 15],
                      chartOptions: {
                        theme: {
                            monochrome: {
                                enabled: true,
                                color: '#9c1b1f',
                                shadeTo: 'light',
                                shadeIntensity: 0.65
                            }
                        },
                        chart: {
                          type: 'donut',
                        },
                        labels: ['Storage', 'Door', 'Meeting', 'Document', 'Padlock', 'Other']
                      },
                      
                      
        }
    },
    methods: {},
    computed: {
        compSeries() {
            return [this.paymentLinks.storage, this.paymentLinks.door, this.paymentLinks.meeting, this.paymentLinks.document, this.paymentLinks.padlock, this.paymentLinks.other]
        }
    }
}
</script>

<style>
    .apexcharts-legend {
        text-align: left !important;
    }
</style>