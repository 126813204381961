<template>
  <v-card elevation="1" class="mb-3 pa-2" style="min-height: 410px;">
    <vue-json-to-csv
      v-if="dateStart.length > 0 && dateEnd.length > 0"
      :json-data="compOccupancyCSV"
      :csv-title="'Occupancy Bookings'"
    >
      <v-btn
        color="primary"
        outlined
        style="position: absolute; top: 10px; right: 10px;"
      >
        Download CSV
      </v-btn>
    </vue-json-to-csv>
    <v-card-title>Occupancy %</v-card-title>
    <v-row class="px-3">
      <v-col cols="12" sm="2">
        <v-select
          v-model="chartType"
          :items="chartTypes"
          label="Percentage Type"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <apexchart
          v-if="dateStart && dateEnd"
          width="100%"
          height="300"
          type="bar"
          :options="compChartOptions"
          :series="compSeries"
        ></apexchart>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
const moment = require("moment");
import VueJsonToCsv from "vue-json-to-csv";
import helpers from "@/plugins/helpers";
export default {
  name: "occupancy",
  components: {
    VueJsonToCsv
  },
  props: {
    location: String,
    dateStart: String,
    dateEnd: String,
    unitTypes: Array
  },
  data() {
    return {
      chartType: "Units Occupied",
      chartTypes: ["Units Occupied", "Sq. Meters Occupied"],
      chartOptions: {
        theme: {
          monochrome: {
            enabled: true,
            color: "#9c1b1f",
            shadeTo: "light",
            shadeIntensity: 0.65
          }
        },
        chart: {
          id: "vuechart-example"
        },
        xaxis: {
          categories: this.compDateAxis
        }
      },
      series: [
        {
          name: "series-1",
          data: []
        }
      ]
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["bookingsAndCustomers"]),
    compBookings() {
      let data = this.bookingsAndCustomers;

      // filter on location
      if (this.location !== "ALL") {
        let results = data.filter(
          d => d.unitDetails.location === this.location
        );
        data = results;
      }

      // // filter on unit types
      if (this.unitTypes != null && this.unitTypes.length !== 0) {
        let results = data.filter(
          d => this.unitTypes.indexOf(d.unitDetails.type) >= 0
        );
        data = results;
      }

      return data;
    },
    compPricing() {
      let data = this.$store.state.pricing;

      // filter on location
      if (this.location !== "ALL") {
        let results = data.filter(d => d.location === this.location);
        data = results;
      }

      return data;
    },
    compUnits() {
      let units = this.$store.state.units;
      let pricing = this.compPricing;

      console.log("units", units.length);
      // filter on location
      if (this.location !== "ALL") {
        let results = units.filter(d => d.location === this.location);
        units = results;
      }
      console.log("units after location filter", units.length);

      // filter on unit types
      if (this.unitTypes != null && this.unitTypes.length !== 0) {
        let results = units.filter(d => this.unitTypes.indexOf(d.type) >= 0);
        units = results;
      }

      console.log("units after type filter", units.length);

      // add dimensions to units
      const combinedArray = units.filter(obj1 => {
        const matchingObj2 = pricing.find(
          obj2 => obj2.location === obj1.location && obj2.type === obj1.type
        );
        if (matchingObj2) {
          let _id = obj1._id;
          Object.assign(obj1, matchingObj2);
          obj1._id = _id;
          // Object.assign(obj1._id, _id);
          return true;
        }
        return false;
      });

      // let results = units.filter(obj1 =>
      //   pricing.some(
      //     obj2 => obj1.location === obj2.location && obj1.type === obj2.type
      //   )
      // );

      console.log("units after dimensions query", combinedArray.length);
      return combinedArray;

      /* const array1 = [
        { location: "New York", type: "A", color: "red" },
        { location: "Tokyo", type: "D", color: "blue" },
        { location: "Paris", type: "C", color: "green" },
        { location: "London", type: "B" },
        { location: "Paris", type: "C" }
      ];

      const array2 = [
        { location: "New York", type: "A" },
        { location: "Tokyo", type: "D", food: "sushi" },
        { location: "Paris", type: "E" }
      ];

      const newArray = array1.filter(obj1 =>
        array2.some(
          obj2 => obj1.location === obj2.location && obj1.type === obj2.type
        )
      );

      const combinedArray = array1.filter(obj1 => {
        const matchingObj2 = array2.find(
          obj2 => obj2.location === obj1.location && obj2.type === obj1.type
        );
        if (matchingObj2) {
          Object.assign(obj1, matchingObj2);
          return true;
        }
        return false;
      });

      console.log(combinedArray);

      return combinedArray; */

      // return newArray;

      // console.log("newArray", newArray);
      // data = newArray;

      // return data;
    },
    compNumDays() {
      if (this.dateStart && this.dateEnd) {
        return moment(this.dateEnd).diff(moment(this.dateStart), "days");
      }
      return 0;
    },
    compDateAxis() {
      if (this.compNumDays) {
        let data = [];
        for (var i = 0; i <= this.compNumDays; i++) {
          data.push(
            moment(this.dateStart)
              .add(i, "days")
              .format("DD-M")
          );
        }
        return data;
      }
      if (this.compNumDays === 0) {
        let data = [];
        data.push(moment(this.dateStart).format("DD-M"));
        return data;
      }
      return [0, 0, 0, 0, 0, 0, 0, 0];
    },
    compOccupancyAxis() {
      if (this.compNumDays) {
        let data = [];
        let selectDate = "";

        for (var i = 0; i <= this.compNumDays; i++) {
          let queryDate = moment(this.dateStart).add(i, "days");
          let dailyBookings = this.compBookings.filter(b => {
            return (
              moment(b.dateStart)
                .startOf("day")
                .isSameOrBefore(queryDate) &&
              moment(b.dateEnd)
                .startOf("day")
                .isSameOrAfter(queryDate)
            );
          });
          if (this.chartType === "Units Occupied") {
            // OCCUPANCY PERCENTAGE:
            let occupancy =
              (dailyBookings.length * 100) / this.compUnits.length;
            data.push(occupancy.toFixed(1));
          } else {
            // OCCUPANCY SQAURE METERS:
            // calculate the total square meters available
            let query = {
              units: this.compUnits,
              date: queryDate,
              filterBookings: false
            };
            const totalSquareMetersAvailable = helpers.totalSquareMeters(query);
            console.log(
              "totalSquareMetersAvailable",
              totalSquareMetersAvailable
            );
            // calculate the total square meters occupied
            const totalSquareMetersOccupied = dailyBookings.reduce(
              (acc, booking) => {
                return (
                  acc + booking.unitDetails.width * booking.unitDetails.length
                );
              },
              0
            );
            let tableQueryDate = moment(queryDate).format("YYYY-MM-DD");
            console.table(
              tableQueryDate,
              totalSquareMetersOccupied,
              totalSquareMetersAvailable,

              (totalSquareMetersOccupied * 100) / totalSquareMetersAvailable
            );
            let occupancy =
              (totalSquareMetersOccupied * 100) / totalSquareMetersAvailable;
            data.push(occupancy.toFixed(1));
          }
        }
        return data;
      }
      if (this.compNumDays === 0) {
        let data = [];

        let dailyBookings = this.compBookings.filter(b => {
          let selectDate = moment(this.dateStart);

          return (
            moment(b.dateStart)
              .startOf("day")
              .isSameOrBefore(selectDate) &&
            moment(b.dateEnd)
              .startOf("day")
              .isSameOrAfter(selectDate)
          );
        });
        let occupancy = (dailyBookings.length * 100) / this.compUnits.length;
        data.push(occupancy.toFixed(1));
        return data;
      }
      return [0];
    },
    compOccupancyCSV() {
      if (this.compNumDays) {
        let data = [];

        for (var i = 0; i <= this.compNumDays; i++) {
          let selectDate = "";
          let dailyBookings = this.compBookings.filter(b => {
            selectDate = moment(this.dateStart).add(i, "days");

            return (
              moment(b.dateStart)
                .startOf("day")
                .isSameOrBefore(selectDate) &&
              moment(b.dateEnd)
                .startOf("day")
                .isSameOrAfter(selectDate)
            );
          });
          let dailyBookingsList = [];
          dailyBookings.forEach(db => {
            dailyBookingsList.push(db.confirmation);
          });
          if (selectDate) {
            data.push({
              date: selectDate.format("DD-M"),
              bookings: dailyBookingsList
            });
          }
        }
        return data;
      }
      return [];
    },
    compChartOptions() {
      return {
        theme: {
          monochrome: {
            enabled: true,
            color: "#9c1b1f",
            shadeTo: "light",
            shadeIntensity: 0.65
          }
        },
        chart: {
          id: "occupancy-by-unit"
        },
        xaxis: {
          categories: this.compDateAxis
        }
      };
    },
    compSeries() {
      return [
        {
          name: "Units",
          data: this.compOccupancyAxis
        }
      ];
    }
  }
};
</script>
