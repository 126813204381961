<template>
  <section class="d-flex flex-column">
    <v-card elevation="1" class="mb-3">
      <v-card-title>ERP Data</v-card-title>
      <v-tabs v-model="activeTab">
        <v-tab to="#tab-1">Sales Invoice</v-tab>
        <v-tab to="#tab-2">Tender Type</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab" class="pa-3">
        <v-tab-item id="tab-1">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="compERPDownload"
            item-key="transactionID"
            show-select
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
          >
            <template v-slot:item.transactionID="{ item }">
              {{ item.transactionID }}
            </template>
            <template v-slot:item.activity="{ item }">
              <v-tooltip
                v-if="item.activity && item.activity.status === 'rejected'"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span>{{ item.activity.response.statusText }}</span>
              </v-tooltip>

              <v-icon
                v-if="item.activity && item.activity.status === 'received'"
                small
                >mdi-check-bold</v-icon
              >
            </template>
          </v-data-table>

          <div class="d-flex flex-row-reverse mt-3">
            <v-btn
              color="primary"
              mt-5
              mx-auto
              :disabled="selected.length === 0"
              @click="sendERP('salesInvoice')"
              :loading="sendingSalesInvoice"
            >
              Send Selected
            </v-btn>
            <vue-json-to-csv
              :json-data="compERPDownload"
              :csv-title="'SALES_INVOICE'"
            >
              <v-btn color="primary" text>
                Download
              </v-btn>
            </vue-json-to-csv>
          </div>
        </v-tab-item>

        <v-tab-item id="tab-2">
          <v-data-table
            v-model="selected2"
            :headers="headersTenderType"
            :items="compERPTenderType"
            item-key="transactionID"
            show-select
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
          >
            <template v-slot:item.transactionID="{ item }">
              {{ item.transactionID }}
            </template>
            <template v-slot:item.activity="{ item }">
              <v-tooltip
                v-if="item.activity && item.activity.status === 'rejected'"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span>{{ item.activity.response.statusText }}</span>
              </v-tooltip>

              <v-icon
                v-if="item.activity && item.activity.status === 'received'"
                small
                >mdi-check-bold</v-icon
              >
            </template>
          </v-data-table>

          <div class="d-flex flex-row-reverse mt-3">
            <v-btn
              color="primary"
              mt-5
              mx-auto
              :disabled="selected2.length === 0"
              @click="sendERP('tenderType')"
              :loading="sendingTenderType"
            >
              Send Selected
            </v-btn>
            <vue-json-to-csv
              :json-data="compERPTenderType"
              :csv-title="'TENDER_TYPE'"
            >
              <v-btn color="primary" text>
                Download
              </v-btn>
            </vue-json-to-csv>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </section>
</template>

<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
import { mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";
const moment = require("moment");
import VueJsonToCsv from "vue-json-to-csv";
import helpers from "@/plugins/helpers";
export default {
  name: "ERP",
  components: {
    VueJsonToCsv
  },
  props: {
    location: String,
    dateStart: String,
    dateEnd: String,
    unitTypes: Array
  },
  data() {
    return {
      sendingSalesInvoice: false,
      sendingTenderType: false,
      activeTab: null,
      selected: [],
      selected2: [],
      activity: [],
      headers: [
        {
          text: "ID",
          align: "start",
          value: "transactionID"
        },

        { text: "Status", value: "activity" },
        { text: "Booking No.", value: "bookingNo" },
        { text: "Type", value: "serviceType" },
        { text: "Total", value: "amount" }
      ],
      headersTenderType: [
        {
          text: "ID",
          align: "start",
          value: "transactionID"
        },
        { text: "Status", value: "activity" },
        { text: "Type", value: "Tender Type" },
        { text: "Total", value: "grandTotal" }
      ]
    };
  },
  methods: {
    isEven(id) {
      // if the last digit of the id is a number, return true
      // if the last digit of the id is a letter, return false
      return !isNaN(id.toString().slice(-1));
    },
    sendERP(erpType) {
      // send compERPDownload to API, which will send to Microsoft Dynamics
      let vm = this;
      let data;
      if (erpType === "salesInvoice") {
        data = vm.selected;
        vm.sendingSalesInvoice = true;
      }
      if (erpType === "tenderType") {
        data = vm.selected2;
        vm.sendingTenderType = true;
      }

      // DEBUG
      // console.log(data);

      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };

      axios
        .post(`${vm.$root.urls.api}/v4/erp/send`, {
          data: data,
          type: erpType,
          date: vm.dateStart
        })
        .then(response => {
          this.$toasted.show(response.data.msg).goAway(2500);
        })
        .catch(error => {
          console.log(error);
          this.$toasted
            .show(
              "Unspecified error. Please check server log for more details.",
              {
                type: "error",
                icon: "mdi-alert-circle"
              }
            )
            .goAway(2500);
        })
        .finally(() => {
          vm.sendingSalesInvoice = false;
          vm.sendingTenderType = false;
          // reload Activity data
          vm.getERPActivity();
        });
    },
    formatERPType(type) {
      if (type.includes("Booking created")) return "Booking Created";
      if (type.includes("Extended by")) return "Extend Booking";
      if (type.includes("extend booking")) return "Extend Booking";
      if (type.includes("Extended booking")) return "Extend Booking";
      if (type.includes("scheduled payment")) return "Scheduled Payment";
      if (type.includes("Customer Changed Billing Date"))
        return "Customer Changed Billing Date";
      if (type.includes("Admin changed billing date"))
        return "Admin changed billing date";
      if (type.includes("Refund Bank Transfer")) return "Refund Bank Transfer";
      if (type.includes("Refund")) return "Refund";
      if (type.includes("Edited by")) return "DELETE";
      if (type.includes("Check in by")) return "DELETE";
      if (type.includes("Check out by")) return "DELETE";
      if (type.includes("cancel subscription")) return "DELETE";
      if (type.includes("subscribe")) return "DELETE";
      return "DELETE";
    },

    getERPActivity() {
      let vm = this;
      vm.activity = [];
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };

      axios
        .get(`${vm.$root.urls.api}/v4/erp/activity`)
        .then(response => {
          console.log(response);
          vm.activity = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    compSalesInvoiceActivity() {
      let data = this.activity;
      let results = data.filter(d => d.type === "Sales Invoice");
      return results;
    },
    compTenderTypeActivity() {
      let data = this.activity;
      let results = data.filter(d => d.type === "Tender Type");
      return results;
    },
    ...mapGetters(["bookingsAndCustomers", "paymentLinksPaid"]),
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    compBookings() {
      let data = this.bookingsAndCustomers;

      // QUICK AND DIRTY FIX: filter bookings that lack a unitDetails
      data = data.filter(d => typeof d.unitDetails !== "undefined");

      // filter on location
      if (this.location !== "ALL") {
        let results = data.filter(
          d => d.unitDetails.location === this.searchLocation
        );
        data = results;
      }

      // filter on startDate. Booking transactions should include at least one transaction AFTER the start date.
      if (this.dateStart) {
        let results = data.filter(d =>
          helpers.includesTransactionsAfter(d.transactions, this.dateStart)
        );

        data = results;
      }

      // filter on endDate. Booking transactions should include at least one transaction BEFORE the end date.
      // if (this.dateStart) {
      //   let results = data.filter(d =>
      //     helpers.includesTransactionsBefore(d.transactions, this.dateStart)
      //   );
      //   data = results;
      // }

      // filter on unit types
      if (this.unitTypes != null && this.unitTypes.length !== 0) {
        let results = data.filter(
          d => this.unitTypes.indexOf(d.unitDetails.type) >= 0
        );
        data = results;
      }

      // sort by createdOn desc
      data.sort(this.sortDates);

      return data;
    },
    compTransactionsCSV() {
      let bookings = this.compBookings;
      let data = [];
      bookings.forEach(b => {
        // filter on transactions that have an amount
        let transactions = b.transactions.filter(t => {
          return typeof t.amount !== "undefined" && t.grandTotal > 0;
        });

        // filter on transactions that are after the start date
        if (transactions && this.dateStart) {
          transactions = transactions.filter(t =>
            moment(t.dateCreated)
              .utcOffset("+07:00")
              .startOf("day")
              .isSameOrAfter(moment(this.dateStart).utcOffset("+07:00"))
          );
        }

        // filter on transactions that are before the end date
        if (transactions && this.dateEnd) {
          transactions = transactions.filter(t =>
            moment(t.dateCreated)
              .utcOffset("+07:00")
              .isSameOrBefore(
                moment(this.dateEnd)
                  .utcOffset("+07:00")
                  .endOf("day")
              )
          );
        }

        transactions.forEach(t => {
          t.bookingNo = t?.bookingDetails?.confirmation
            ? t.bookingDetails.confirmation
            : "";
          if (isNaN(t.grandTotal)) {
            console.log(`${t._id} ${t.confirmation} ${t.grandTotal}`);
          }
          data.push(t);
        });
      });
      return data;
    },

    compERP() {
      // INIT
      let outputData = [];

      // FLATTEN BOOKING TRANSACTION DATA
      const transactions = this.compBookings.flatMap(obj => obj.transactions);

      // REMOVE TRANSACTIONS THAT DONT HAVE AN AMOUNT
      const filteredTransactions = transactions.filter(t => t.amount > 0);

      // REMOVE TRANSACTIONS THAT DONT MATCH THE START DATE
      const filteredTransactions2 = filteredTransactions.filter(t =>
        moment(t.dateCreated).isSame(moment(this.dateStart), "day")
      );

      // ADD CUSTOMER DETAILS TO EACH TRANSACTION
      filteredTransactions2.forEach(t => {
        let customer = this.$store.state.users.find(
          u => u._id === t.customerID
        );
        if (customer) {
          t.customerDetails = customer;
        }
      });

      // ADD BOOKING DETAILS TO EACH TRANSACTION
      filteredTransactions2.forEach(t => {
        const booking = this.compBookings.find(parent => {
          return parent.transactions.some(child => child._id === t._id);
        });
        if (booking) {
          t.bookingDetails = booking;
        }
      });

      // LOOP TRANSACTIONS
      filteredTransactions2.forEach(t => {
        // CREATE ERP RECORD OBJECT
        let erp = {
          _id: t._id,
          "Line No.": 10000,
          confirmation: t?.bookingDetails?.confirmation,
          type: this.formatERPType(t.type),
          total: t.amount,
          unitID: t?.bookingDetails?.unitID,
          title: t?.customerDetails?.title,
          FirstName: t?.customerDetails?.firstname,
          LastName: t?.customerDetails?.lastname,
          Phone: helpers.formatBlank(t?.customerDetails?.phone),
          CompanyName: helpers.formatBlank(t?.customerDetails?.companyName),
          TaxCode: helpers.formatBlank(t?.customerDetails?.taxCode),
          address1: helpers.formatBlank(t?.customerDetails?.companyAddress1),
          district: helpers.formatBlank(t?.customerDetails?.companyDistrict),
          subDistrict: helpers.formatBlank(
            t?.customerDetails?.companySubDistrict
          ),
          province: helpers.formatBlank(t?.customerDetails?.companyProvince),
          Country: helpers.formatBlank(t?.customerDetails?.companyCountry),
          Postcode: helpers.formatBlank(t?.customerDetails?.companyPostcode),
          Email: helpers.formatBlank(t?.customerDetails?.username),
          "Transaction Date": moment(t.dateCreated)
            .local()
            .format("YYYY-MM-DD"),
          "Start Date": moment(t.dateStart)
            .local()
            .format("YYYY-MM-DD"),
          "End Date": moment(t.dateEnd)
            .local()
            .format("YYYY-MM-DD"),
          "Service Type": "Storage Service",
          "Ref ID No": "",
          Location: t?.bookingDetails?.unitDetails?.location,
          WHT: t.wht ? t.wht : false,
          "Customer Branch No.": t?.customerDetails?.companyBranch
            ? `'${t.customerDetails.companyBranch}`
            : "'00000",
          CustomerType: t.customerDetails.type
            ? t.customerDetails.type
            : "Personal",
          tenderType: helpers.formatBlank(t.paymentMethod),
          cardDetails: helpers.formatBlank(t.cardDetails),
          discount: t.discount
        };

        // IF ERP TYPE = REFUND, ADD REFUND ID
        if (t.type === "Refund") {
          erp["Ref ID No"] = t.notes.replace("Refund for transaction ", "");
          erp.tenderType = "Refund";
        }
        if (t.type === "Refund Bank Transfer") {
          erp["Ref ID No"] = t.notes.replace("Refund for transaction ", "");
          erp.tenderType = "Refund Bank Transfer";
        }

        if (erp.type !== "DELETE") outputData.push(erp);
      });

      // if booking.type includes the text "changed billing date" change the text to "Admin changed billing date"
      outputData.forEach(d => {
        if (d.type.toLowerCase().includes("changed billing date")) {
          d.type = "Ext New Bill Cycle";
        }
      });

      // GET PAYMENT LINKS
      const paymentLinks = this.compPaymentLinks;

      // REMOVE TRANSACTIONS THAT DONT MATCH THE START DATE
      const filteredPaymentLinks = paymentLinks.filter(pl =>
        moment(pl.charge.used_at).isSame(moment(this.dateStart), "day")
      );

      // ADD CUSTOMER DETAILS TO EACH PAYMENT LINK
      filteredPaymentLinks.forEach(pl => {
        let customer = this.$store.state.users.find(
          u => u._id === pl.customerID
        );
        if (customer) {
          pl.customerDetails = customer;
        }
      });

      // ADD BOOKING DETAILS TO EACH PAYMENT LINK
      filteredPaymentLinks.forEach(pl => {
        const booking = this.compBookings.find(
          b => b.confirmation === pl.bookingNumber
        );
        if (booking) {
          pl.bookingDetails = booking;
        }
      });

      // console.log("filteredPaymentLinks", filteredPaymentLinks);

      // LOOP PAYMENT LINKS
      filteredPaymentLinks.forEach(pl => {
        // LOOP PAYMENT LINK LINE ITEMS
        pl.lines.forEach((line, index) => {
          // CREATE ERP RECORD OBJECT
          let erp = {
            _id: pl.charge.charges.data[0].id,
            "Line No.": 10000 * (index + 1),
            confirmation: helpers.formatBlank(pl?.bookingNumber),
            type: "Payment Link",
            total: line.price * line.quantity,
            unitID: helpers.formatBlank(pl?.bookingDetails?.unitID),
            title: pl?.customerDetails?.title,
            FirstName: pl?.customerDetails?.firstname,
            LastName: pl?.customerDetails?.lastname,
            Phone: pl?.customerDetails?.phone,
            CompanyName: helpers.formatBlank(pl?.customerDetails?.companyName),
            TaxCode: helpers.formatBlank(pl?.customerDetails?.taxCode),
            address1: helpers.formatBlank(pl?.customerDetails?.companyAddress1),
            district: pl?.customerDetails?.companyDistrict,
            subDistrict: pl?.customerDetails?.companySubDistrict,
            province: pl?.customerDetails?.companyProvince,
            Country: pl?.customerDetails?.companyCountry,
            Postcode: pl?.customerDetails?.companyPostcode,
            Email: pl?.customerDetails?.username,
            "Transaction Date": moment(pl.charge.used_at)
              .local()
              .format("YYYY-MM-DD"),
            "Start Date": moment(pl.charge.used_at)
              .local()
              .format("YYYY-MM-DD"),
            "End Date": moment(pl.charge.used_at)
              .local()
              .format("YYYY-MM-DD"),
            "Service Type": pl.lines[index].service,
            "Ref ID No": "",
            Location: helpers.formatBlank(
              pl?.bookingDetails?.unitDetails?.location
            ),
            WHT: false,
            "Customer Branch No.": pl?.customerDetails?.companyBranch
              ? `'${pl.customerDetails.companyBranch}`
              : "'00000",
            CustomerType: pl?.customerDetails?.type,
            tenderType: "OPN Credit Card",
            cardDetails: `${pl.charge.charges.data[0].card.brand} ${pl.charge.charges.data[0].card.last_digits}`
          };

          outputData.push(erp);
        });
      });

      // if customerType === "undefined", give it a value
      outputData.forEach(d => {
        // console.log(d.CustomerType);
        // console.log(typeof d.CustomerType);
        if (d.CustomerType === "undefined") {
          d.CustomerType = "Personal";
        }
      });

      // add the activity to the outputData with the same transactionID
      if (
        this.compSalesInvoiceActivity &&
        this.compSalesInvoiceActivity.length > 0
      ) {
        // console.log(`checking activity...`);
        outputData.forEach(d => {
          let activity = this.compSalesInvoiceActivity.filter(
            a => a.transactionID === d._id
          );
          // console.log(activity.length);

          // debug chrg_60qkm6uvhyjwo9y4by6
          // if (d._id === "chrg_60qkm6uvhyjwo9y4by6") {
          //   console.log(`activity found for chrg_60qkm6uvhyjwo9y4by6`);
          //   console.log(activity);
          // }

          // // if there is more than one activity, add the most recent one
          if (activity && activity.length > 1) {
            // console.log(`more than 1 activity found`);
            // sort by createdOn
            activity.sort((a, b) => {
              return new Date(b.createdOn) - new Date(a.createdOn);
            });
            d.activity = activity[0];
          }
          if (activity && activity.length === 1) {
            d.activity = activity[0];
            // console.log(`1 activity found`);
          }

          // d.activity = activity;
        });
      }

      return outputData;

      //return filteredTransactions2;
    },
    compERPDownload() {
      // if there is no activity, return an empty array
      if (
        !this.compSalesInvoiceActivity ||
        this.compSalesInvoiceActivity.length === 0
      ) {
        return [];
      }

      let data = this.compERP;
      let outputData = [];
      // loop data and create new object
      data.forEach(d => {
        let record = {
          transactionID: d._id ? d._id : "no-transactionID",
          lineNo: d["Line No."],
          bookingNo: d.confirmation ? d.confirmation : "no-bookingNo",
          bookingType: d.type ? d.type : "no-bookingType",
          amount: d.total ? d.total : 0,
          unitID: d.unitID ? d.unitID : "no-unitID",
          title: d.title ? d.title : "no-title",
          firstName: d.FirstName ? d.FirstName : "no-firstName",
          lastName: d.LastName ? d.LastName : "no-lastName",
          phone: d.Phone ? d.Phone : "-",
          customerName: d.CompanyName ? d.CompanyName : "-",
          vatRegistrationNo: d.TaxCode ? d.TaxCode : "-",
          address: d.address1 ? d.address1 : "-",
          district: d.district ? d.district : "-",
          subDistrict: d.subDistrict ? d.subDistrict : "-",
          province: d.province ? d.province : "-",
          country: d.Country ? d.Country : "-",
          postCode: d.Postcode ? d.Postcode : "-",
          eMail: d.Email,
          transactionDate: moment(d["Transaction Date"]).format(),
          startDate: moment(d["Start Date"]).format(),
          endDate: moment(d["End Date"]).format(),
          serviceType: d["Service Type"] ? d["Service Type"] : "no-serviceType",
          refTransactionID: d["Ref ID No"] ? d["Ref ID No"] : "",
          locationCode: d.Location ? d.Location : "no-loci",
          wht: d.WHT ? d.WHT : false,
          customerBranchNo: d["Customer Branch No."]
            ? d["Customer Branch No."]
            : "'00000",
          customerType: d.CustomerType ? d.CustomerType : "Personal",
          activity: d.activity
        };

        outputData.push(record);
      });

      return outputData;
    },
    compERPTenderType() {
      let data = this.compERP;
      let outputData = [];
      // loop data and create new object
      data.forEach(d => {
        let tender = {
          transactionID: d._id,
          tenderType: d.tenderType,
          cardDetail: d.cardDetails,
          grandTotal: d.total - (d.discount ? d.discount : 0)
        };
        outputData.push(tender);

        // INCLUDE A DISCOUNT LINE IF THE TRANSACTION IS A REFUND
        if (d.discount && d.discount > 0) {
          let discount = {
            transactionID: d._id,
            tenderType: "Discount",
            cardDetail: "",
            grandTotal: d.discount
          };
          outputData.push(discount);
        }
      });

      // change "tender type" refund to "Refund OPN"
      outputData.forEach(d => {
        if (d["tenderType"] === "Refund") {
          d["tenderType"] = "Refund OPN";
        }
      });

      // add the activity to the outputData with the same transactionID
      if (
        this.compTenderTypeActivity &&
        this.compTenderTypeActivity.length > 0
      ) {
        // console.log(
        //   `compTenderTypeActivity length ${this.compTenderTypeActivity.length}`
        // );
        // console.log(`checking activity...`);
        outputData.forEach(d => {
          let activity = this.compTenderTypeActivity.filter(
            a => a.transactionID === d.transactionID
          );
          // console.log(activity.length);

          // if there is more than one activity, add the most recent one
          if (activity && activity.length > 1) {
            // console.log(`more than 1 activity found`);
            // sort by createdOn
            activity.sort((a, b) => {
              return new Date(b.createdOn) - new Date(a.createdOn);
            });
            d.activity = activity[0];
          }
          if (activity && activity.length === 1) {
            d.activity = activity[0];
            // console.log(`1 activity found`);
          }
          if (activity && activity.length === 0) {
            // console.log(`no activity found`);
          }

          // d.activity = activity;
        });
      }

      return outputData;
    },
    compPaymentLinks() {
      // v2
      let paymentLinks = this.paymentLinksPaid;
      // filter on transactions that are after the start date
      if (this.dateStart && this.dateEnd) {
        let filteredLinks = [];
        paymentLinks.forEach(pl => {
          if (
            moment(pl.charge.used_at)
              .utcOffset("+07:00")
              .isSameOrAfter(
                moment(this.dateStart)
                  .utcOffset("+07:00")
                  .startOf("day")
              ) &&
            moment(pl.charge.used_at)
              .utcOffset("+07:00")
              .isSameOrBefore(
                moment(this.dateEnd)
                  .utcOffset("+07:00")
                  .endOf("day")
              )
          ) {
            filteredLinks.push(pl);
          }
        });
        return filteredLinks;
      }
      return paymentLinks;
    }
  },
  mounted() {
    this.getERPActivity();
  }
};
</script>
