<template>
  <div>
    <v-card elevation="1" class="mb-3">
      <v-card-title>Revenue</v-card-title>

      <v-card elevation="1" class="ma-3 pa-2">
        <v-row class="d-flex justify-start">
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <v-card-subtitle class="text-left mb-0 pb-0"
              ><sup
                ><vue-json-to-csv
                  :json-data="compMasterRevenueData"
                  :csv-title="'transactions'"
                >
                  <v-icon color="primary">mdi-file-download-outline</v-icon>
                </vue-json-to-csv></sup
              >
              TOTAL REVENUE
            </v-card-subtitle>
            <p class="text-h4 text--primary mb-0 text-left pl-5">
              {{
                formatNumber(
                  compRevenueTotal.total +
                    compPaymentLinksBookingsSummary.total +
                    compPaymentLinksNoBookingsSummary.total
                )
              }}
            </p>
          </v-col>
        </v-row>
      </v-card>

      <v-card elevation="1" class="ma-3 pa-2">
        <v-row class="d-flex justify-start">
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <v-card-subtitle class="text-left mb-0 p-0 pb-0"
              ><sup
                ><vue-json-to-csv
                  :json-data="compMasterRevenueDataBookings"
                  :csv-title="'transactions'"
                >
                  <v-icon color="primary">mdi-file-download-outline</v-icon>
                </vue-json-to-csv></sup
              >
              BOOKINGS REVENUE
            </v-card-subtitle>
            <p class="text-h4 text--primary mb-0 text-left pl-5">
              {{
                formatNumber(
                  compRevenueTotal.total + compPaymentLinksBookingsSummary.total
                )
              }}
            </p>
          </v-col>

          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{
                formatNumber(
                  compRevenueTotal.total +
                    compPaymentLinksBookingsSummary.storage
                )
              }}
            </p>
            <span>Storage</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksBookingsSummary.door) }}
            </p>
            <span>Door-to-Door</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksBookingsSummary.meeting) }}
            </p>
            <span>Meeting Room</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksBookingsSummary.document) }}
            </p>
            <span>Document Service</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksBookingsSummary.padlock) }}
            </p>
            <span>Padlock Removal</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksBookingsSummary.other) }}
            </p>
            <span>Other</span>
          </v-col>
        </v-row>
      </v-card>

      <v-card elevation="1" class="ma-3 pa-2">
        <v-row class="d-flex justify-start">
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <v-card-subtitle class="text-left mb-0 p-0 pb-0"
              ><sup
                ><vue-json-to-csv
                  :json-data="compMasterRevenueDataPaymentLinks"
                  :csv-title="'transactions'"
                >
                  <v-icon color="primary">mdi-file-download-outline</v-icon>
                </vue-json-to-csv></sup
              >
              OTHER REVENUE
            </v-card-subtitle>
            <p class="text-left text-h4 text--primary mb-0 pl-5">
              {{ formatNumber(compPaymentLinksNoBookingsSummary.total) }}
            </p>
          </v-col>

          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksNoBookingsSummary.storage) }}
            </p>
            <span>Storage</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksNoBookingsSummary.door) }}
            </p>
            <span>Door-to-Door</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksNoBookingsSummary.meeting) }}
            </p>
            <span>Meeting Room</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksNoBookingsSummary.document) }}
            </p>
            <span>Document Service</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksNoBookingsSummary.padlock) }}
            </p>
            <span>Padlock Removal</span>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="text-center mb-6">
            <p class="text-h5 text--primary mb-0 mt-5">
              {{ formatNumber(compPaymentLinksNoBookingsSummary.other) }}
            </p>
            <span>Other</span>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="d-flex justify-start">
        <v-col cols="12" sm="6" class="text-center mb-6">
          <v-card-subtitle>With Bookings <sup>(THB)</sup></v-card-subtitle>
          <RevenuePie :paymentLinks="compPaymentLinksBookingsSummary" />
        </v-col>
        <v-col cols="12" sm="6" class="text-center mb-6">
          <v-card-subtitle>Without Bookings <sup>(THB)</sup></v-card-subtitle>
          <RevenuePie :paymentLinks="compPaymentLinksNoBookingsSummary" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RevenuePie from "@/components/Reports/RevenuePie.vue";
const moment = require("moment");
import VueJsonToCsv from "vue-json-to-csv";
import helpers from "@/plugins/helpers";
export default {
  name: "revenue",
  components: {
    VueJsonToCsv,
    RevenuePie
  },
  props: {
    location: String,
    dateStart: String,
    dateEnd: String,
    unitTypes: Array
  },
  data() {
    return {};
  },
  methods: {
    formatStringNumber(s) {
      let num = String(s);
      num = num.replace(/[^\d.]/g, "");
      return Number(num);
    },
    formatNumber(n) {
      return Number(n).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    sortDates(a, b) {
      // Sort dates
      return moment(a.createdOn).isBefore(moment(b.createdOn));
    },
    getPaymentLinkSummary(data) {
      // v2
      let summary = {
        total: 0,
        storage: 0,
        door: 0,
        meeting: 0,
        document: 0,
        padlock: 0,
        other: 0
      };

      if (data) {
        data.forEach(pl => {
          // filter on transactions that are after the start date

          pl.lines.forEach(l => {
            summary.total =
              summary.total + Number(l.price) * Number(l.quantity);
            if (l.service === "Storage service")
              summary.storage =
                summary.storage + Number(l.price) * Number(l.quantity);
            if (l.service === "Door-to-Door service")
              summary.door =
                summary.door + Number(l.price) * Number(l.quantity);
            if (l.service === "Meeting room service")
              summary.meeting =
                summary.meeting + Number(l.price) * Number(l.quantity);
            if (l.service === "Document service")
              summary.document =
                summary.document + Number(l.price) * Number(l.quantity);
            if (
              l.service === "Padlock removal service" ||
              l.service === "Break a padlock service"
            )
              summary.padlock =
                summary.padlock + Number(l.price) * Number(l.quantity);
            if (l.service === "Other service")
              summary.other =
                summary.other + Number(l.price) * Number(l.quantity);
          });
        });
      }

      return summary;
    }
  },
  computed: {
    ...mapGetters(["bookingsAndCustomers", "paymentLinksPaid"]),
    compBookings() {
      let data = this.bookingsAndCustomers;

      // filter on location
      if (this.location !== "ALL") {
        let results = data.filter(
          d => d.unitDetails.location === this.location
        );
        data = results;
      }

      // // filter on unit types
      if (this.unitTypes != null && this.unitTypes.length !== 0) {
        let results = data.filter(
          d => this.unitTypes.indexOf(d.unitDetails.type) >= 0
        );
        data = results;
      }

      return data;
    },
    compPricing() {
      let data = this.$store.state.pricing;

      // filter on location
      if (this.location !== "ALL") {
        let results = data.filter(d => d.location === this.location);
        data = results;
      }

      return data;
    },
    compUnits() {
      let units = this.$store.state.units;
      let pricing = this.compPricing;

      console.log("units", units.length);
      // filter on location
      if (this.location !== "ALL") {
        let results = units.filter(d => d.location === this.location);
        units = results;
      }
      console.log("units after location filter", units.length);

      // filter on unit types
      if (this.unitTypes != null && this.unitTypes.length !== 0) {
        let results = units.filter(d => this.unitTypes.indexOf(d.type) >= 0);
        units = results;
      }

      console.log("units after type filter", units.length);

      // add dimensions to units
      const combinedArray = units.filter(obj1 => {
        const matchingObj2 = pricing.find(
          obj2 => obj2.location === obj1.location && obj2.type === obj1.type
        );
        if (matchingObj2) {
          let _id = obj1._id;
          Object.assign(obj1, matchingObj2);
          obj1._id = _id;
          // Object.assign(obj1._id, _id);
          return true;
        }
        return false;
      });

      console.log("units after dimensions query", combinedArray.length);
      return combinedArray;
    },
    compRevenueTotal() {
      // v1
      let total = 0;
      let summary = {
        total: 0,
        storage: 0
        // paylink: 0
      };
      let bookings = this.compBookings;
      let transactionsCSV = [];
      bookings.forEach(b => {
        // filter on transactions that have an amount
        let transactions = b.transactions.filter(t => {
          return typeof t.amount !== "undefined" && t.grandTotal > 0;
        });

        // filter on transactions that are after the start date
        if (transactions && this.dateStart) {
          transactions = transactions.filter(t =>
            moment(t.dateCreated)
              .utcOffset("+07:00")
              .startOf("day")
              .isSameOrAfter(moment(this.dateStart).utcOffset("+07:00"))
          );
        }

        // filter on transactions that are before the end date
        if (transactions && this.dateEnd) {
          transactions = transactions.filter(t =>
            moment(t.dateCreated)
              .utcOffset("+07:00")
              .isSameOrBefore(
                moment(this.dateEnd)
                  .utcOffset("+07:00")
                  .endOf("day")
              )
          );
        }

        transactions.forEach(t => {
          t.bookingNo = t?.bookingDetails?.confirmation
            ? t.bookingDetails.confirmation
            : "";
          if (isNaN(t.grandTotal)) {
            console.log(`${t._id} ${t.confirmation} ${t.grandTotal}`);
          }
          summary.total = summary.total + t.grandTotal;
          transactionsCSV.push(t);
          if (t.type !== "Payment Link Purchase")
            summary.storage = summary.storage + t.grandTotal;
        });
      });
      this.transactionsCSV = transactionsCSV;
      return summary;
    },
    compPaymentLinksBookingsSummary() {
      return this.getPaymentLinkSummary(this.compPaymentLinksBookings);
    },
    compPaymentLinksBookings() {
      //v2
      return this.compPaymentLinks.filter(pl => pl.bookingNumber !== null);
    },
    compPaymentLinks() {
      // v2
      let paymentLinks = this.paymentLinksPaid;
      // filter on transactions that are after the start date
      if (this.dateStart && this.dateEnd) {
        let filteredLinks = [];
        paymentLinks.forEach(pl => {
          if (
            moment(pl.charge.used_at)
              .utcOffset("+07:00")
              .isSameOrAfter(
                moment(this.dateStart)
                  .utcOffset("+07:00")
                  .startOf("day")
              ) &&
            moment(pl.charge.used_at)
              .utcOffset("+07:00")
              .isSameOrBefore(
                moment(this.dateEnd)
                  .utcOffset("+07:00")
                  .endOf("day")
              )
          ) {
            filteredLinks.push(pl);
          }
        });
        return filteredLinks;
      }
      return paymentLinks;
    },
    compMasterRevenueData() {
      const bookingData = this.compBookings;
      const paymentLinkData = this.compPaymentLinks;

      let data = [];

      // console.log(`compMasterRevenueData`);
      bookingData.forEach(b => {
        // filter on transactions that have an amount
        let transactions = b.transactions.filter(t => {
          return typeof t.amount !== "undefined" && t.grandTotal > 0;
        });

        // filter on transactions that are after the start date
        if (transactions && this.dateStart) {
          transactions = transactions.filter(t =>
            moment(t.dateCreated)
              .utcOffset("+07:00")
              .startOf("day")
              .isSameOrAfter(moment(this.dateStart).utcOffset("+07:00"))
          );
          // transactions = transactions.filter(t => moment(t.dateCreated).isAfter(moment(this.dateStart)))
        }

        // filter on transactions that are before the end date
        if (transactions && this.dateEnd) {
          transactions = transactions.filter(t =>
            moment(t.dateCreated)
              .utcOffset("+07:00")
              .isSameOrBefore(
                moment(this.dateEnd)
                  .utcOffset("+07:00")
                  .endOf("day")
              )
          );
          // transactions = transactions.filter(t => moment(t.dateCreated).isBefore(moment(this.dateEnd)))
        }

        // console.log(transactions)
        transactions.forEach(t => {
          // if ( isNaN(t.grandTotal) ) {
          //  console.log(`${t._id} ${t.confirmation} ${t.grandTotal}`)
          // }
          let revenueItem = {
            id: t._id,
            date: t.dateCreated,
            amount: Number(t.grandTotal),
            bookingNo: b.confirmation,
            type: t.type,
            isPaymentLink: false
          };
          data.push(revenueItem);
        });
      });

      // loop paymentlink transactions
      paymentLinkData.forEach(pl => {
        if (pl.charge.used_at) {
          pl.lines.forEach(item => {
            let revenueItem = {
              id: pl._id,
              date: pl.charge.used_at,
              amount: Number(item.price),
              bookingNo: pl.bookingNumber,
              type: item.service,
              isPaymentLink: true
            };

            data.push(revenueItem);
          });
        }
      });

      return data;
    },
    compPaymentLinksNoBookingsSummary() {
      return this.getPaymentLinkSummary(this.compPaymentLinksNoBookings);
    },
    compMasterRevenueDataBookings() {
      let data = this.compMasterRevenueData.filter(m => m.bookingNo);
      return data;
    },
    compMasterRevenueDataPaymentLinks() {
      let data = this.compMasterRevenueData.filter(
        m => m.isPaymentLink && m.bookingNo === null
      );
      return data;
    },
        compPaymentLinksNoBookings() {
      //v2
      return this.compPaymentLinks.filter(pl => pl.bookingNumber === null);
    },

  }
};
</script>
